import {DrupalParagraph, DrupalTaxonomyTerm} from "next-drupal";
import {useState} from "react";
import {Link} from "../../atoms/link";
import {IconType, ImageIcon} from "../../atoms/icon";
import classNames from "classnames";

function IconButton({icon}: {icon: DrupalTaxonomyTerm}) {
  const [hover, setHover] = useState<boolean>();

  return (
    <div className="border-[3px] border-primary hover:bg-primary rounded-md w-16 h-16 flex justify-center items-center transition-all duration-200 relative" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <ImageIcon type={icon.name as IconType} className={classNames("absolute duration-200", {"opacity-0": !hover})} color={"white"} width={50} height={50} alt={icon.name} style={{objectFit: "cover", margin: "auto", maxHeight: "40px", width: "auto"}} />
      <ImageIcon type={icon.name as IconType} className={classNames("absolute duration-200", {"opacity-0": hover})} color={"primary"} width={50} height={50} alt={icon.name} style={{objectFit: "cover", margin: "auto", maxHeight: "40px", width: "auto"}} />
    </div>
  )
}

export function ParagraphIconButtons({paragraph}: {paragraph: DrupalParagraph}) {
  // Filter out any references that no longer exist.
  const items = paragraph?.field_icon_buttons_items?.length
    ? paragraph.field_icon_buttons_items.filter(item => item?.type !== "unknown" && item?.id) : [];
  if (!items.length) return <></>

  return (
    <div className="grid grid-cols-3 gap-4 gap-y-6 md:gap-y-0 md:gap-8 md:flex md:justify-center">
      {items.map(item => (
        <Link key={`${paragraph?.id}--${item?.id}--${item?.title}`} href={item?.field_icon_button_link.url ?? "/"} className="text-primary font-bold text-center flex flex-col justify-start items-center">
          <IconButton icon={item.field_icon_button_icon}/>
          <div className="max-w-[130px] pt-2 text-sm md:text-base !leading-tight">{item.title}</div>
        </Link>
      ))}
    </div>
  )
}